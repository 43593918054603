@import url('https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spirax&display=swap');

.App {
  text-align: center;
  background-image: url('./assets/bg.jpg');
  background-size: cover;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  background-color: rgba(255, 255, 255, 0.4);
  /* background-color: rgba(0, 0, 0, 0.65); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.comming-soon {
  margin: 2vmin;
  display: inline-block;
  vertical-align: middle;
  font-size: 5vmin;
  font-family: 'Spirax', cursive;
  color: rgb(30, 51, 29);
}
.company-name {
  font-family: 'Uncial Antiqua', cursive;
  font-size: 10vmin;
  color: rgb(30, 51, 29);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.lds-heart {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 5vmin;
  height: 5vmin;
  transform: rotate(45deg);
  transform-origin: 2.5vmin 2.5vmin;
}
.lds-heart div {
  top: 2vmin;
  left: 2vmin;
  position: absolute;
  width: 2vmin;
  height: 2vmin;
  background: rgb(30, 51, 29);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 2vmin;
  height: 2vmin;
  background: rgb(30, 51, 29);
}
.lds-heart div:before {
  left: -1.5vmin;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -1.5vmin;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
